import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "scrollArea", "pagination" ];

  connect() {
    const observer = new IntersectionObserver(
      entries => this.handleIntersect(entries),
      {
        // https://github.com/w3c/IntersectionObserver/issues/124#issuecomment-476026505
        threshold: [0, 1.0],
      }
    );
    observer.observe(this.scrollAreaTarget);
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const next = this.paginationTarget.querySelector("[rel=next]")
        if (!next) return;
        if (next.textContent == "Load More...") {
          next.textContent = "Loading More...";
          this.loadMore({ target: next, preventDefault: () => {} });
        }
      }
    });
  }

  async loadMore(event) {
    event.preventDefault();
    const { href } = event.target;

    const response = await fetch(href, { headers: { Accept: "text/vnd.turbo-stream.html" } });
    const html = await response.text();

    Turbo.renderStreamMessage(html);
  }
}
