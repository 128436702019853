// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()

// Configure Stimulus development experience
window.Stimulus.warnings = true
window.Stimulus.debug    = false

// Import and register all your controllers within this directory and all subdirectories
// Controller files must be named *_controller.js or *_controller.ts
const context = require.context("controllers", true, /_controller\.(js|ts)$/)
window.Stimulus.load(definitionsFromContext(context))
