import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'input', 'currentValue', 'menu', 'option'];

  connect() {
    this.menuTarget.classList.add('hidden');
  }

  toggleMenu(e) {
    e.preventDefault();
    this.menuTarget.classList.toggle('hidden');
  }

  selectValue({ currentTarget }) {
    const { dataset: { selectDropdownValue, selectDropdownPlaceholder } } = currentTarget;
    const newValue = currentTarget.cloneNode(true);
    newValue.removeChild(newValue.children[newValue.children.length - 1]);

    this.menuTarget.querySelectorAll('.bi-check').forEach((c) => c.classList.add('hidden'));
    currentTarget.querySelector('.bi-check').classList.remove('hidden');

    this.fieldTarget.value = selectDropdownValue;
    this.inputTarget.setAttribute('placeholder', selectDropdownPlaceholder);
    this.currentValueTarget.innerHTML = newValue.innerHTML;

    this.menuTarget.classList.toggle('hidden');
  }
}
