import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "showView", "editView" ];

  connect() {
    this.renderShow({ preventDefault: () => {} });
  }

  submitForm(e) {
    e.preventDefault();
    this.editViewTargets.forEach((view) => {
      const form = view.querySelector('form');
      form && form.submit();
    });
  }

  renderEdit(e) {
    e.preventDefault();
    this.showViewTargets.forEach((view) => view.classList.add('hidden'));
    this.editViewTargets.forEach((view) => view.classList.remove('hidden'));
  }

  renderShow(e) {
    e.preventDefault();
    this.editViewTargets.forEach((view) => view.classList.add('hidden'));
    this.showViewTargets.forEach((view) => view.classList.remove('hidden'));
  }
}
