import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['badge']

  connect() {
    this.currentBadgeCount = parseInt(this.badgeTarget.textContent);
    this.renderBadge();
  }

  update({ currentTarget }) {
    const { dataset: { read, id } } = currentTarget;

    if (read == 'false') {
      currentTarget.dataset.read = true;
      this.currentBadgeCount--;
      this.renderBadge();
      Rails.ajax({ type: 'PATCH', url: `/notifications/${id}/read` });
    }
  }

  renderBadge() {
    this.badgeTarget.textContent = this.currentBadgeCount;
    if (this.currentBadgeCount <= 0) {
      this.badgeTarget.style.display = 'none';
    }
  }
}
