import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["stage", "nextButton", "previousButton", "cancelButton"];

  initialize() {
    this.currentStage = 0;
    this.form = document.getElementById(this.element.getAttribute("data-form-id"))
  }

  connect() {
    this.cancelButtonTarget.textContent = window.I18n.wizard_controller.cancel;
    this.nextButtonTarget.textContent = window.I18n.wizard_controller.next;
    this.previousButtonTarget.textContent = window.I18n.wizard_controller.previous;
    this.previousButtonTarget.classList.add('d-none');
    this.stageTargets.map((stage) => stage.classList.add('d-none'));
    this.stageTargets[this.currentStage].classList.remove('d-none');
  }

  nextStage(event) {
    event.preventDefault();

    if (this.isFinalStage()) {
      this.form.submit();
    } else {
      this.currentStage += 1;
      this.stageTargets.map((stage) => stage.classList.add('d-none'));
      this.stageTargets[this.currentStage].classList.remove('d-none');
      this.updateButtons();
    }
  }

  previousStage(event) {
    event.preventDefault();
    this.currentStage -= 1;
    this.stageTargets.map((stage) => stage.classList.add('d-none'));
    this.stageTargets[this.currentStage].classList.remove('d-none');
    this.updateButtons();
  }

  updateButtons() {
    if (this.isFinalStage()) {
      this.nextButtonTarget.textContent = window.I18n.wizard_controller.save;
    } else {
      this.nextButtonTarget.textContent = window.I18n.wizard_controller.next;
    }

    if (this.currentStage == 0) {
      this.previousButtonTarget.classList.add('d-none');
    } else {
      this.previousButtonTarget.classList.remove('d-none');
    }
  }

  isFinalStage() {
    return this.currentStage == this.stageTargets.length - 1;
  }
}
