import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "completionScore",
    "completionChecklist",
    "searchabilityScore",
    "searchabilityChecklist"
  ];

  connect() {
    this.update();
    $(this.element).on('change', 'input, select', () => setTimeout(() => this.update(), 50));
  }

  update() {
    this.updateCompletion();
    this.updateSearchability();
  }

  updateCompletion() {
    const checklistItems = this.completionChecklistTarget.querySelectorAll('li');
    const totalCount     = checklistItems.length;

    checklistItems.forEach((item) => {
      if (this.isFieldCompleted(item.dataset.inputSelector)) {
        item.classList.add('completed');
      } else {
        item.classList.remove('completed');
      }
    });

    const completedCount  = this.completionChecklistTarget.querySelectorAll('li.completed').length;
    const completePercent = Math.round(completedCount / totalCount * 100);

    this.completionScoreTarget.textContent = `${completePercent}%`;
  }

  updateSearchability() {
    const checklistItems = this.searchabilityChecklistTarget.querySelectorAll('li');

    checklistItems.forEach((item) => {
      if (this.isFieldCompleted(item.dataset.inputSelector)) {
        item.classList.add('completed');
      } else {
        item.classList.remove('completed');
      }
    });

    let searchabilityPoints = 0;
    this.searchabilityChecklistTarget.querySelectorAll('li.completed').forEach((item) => {
      let pointMultiplier = 1;
      if (item.dataset.searchabilityListDelimeter) {
        const inputField = this.element.querySelector(item.dataset.inputSelector);
        pointMultiplier = inputField.value.split(item.dataset.searchabilityListDelimeter).length
      }
      searchabilityPoints += parseInt(item.dataset.searchabilityPoints) * pointMultiplier;
    });

    this.searchabilityScoreTarget.textContent = searchabilityPoints;
  }

  isFieldCompleted(selector) {
    if (selector == 'avatar') {
      return !!this.element.querySelector('[data-edit-avatar-target="preview"]').style.backgroundImage;
    }

    const inputField = this.element.querySelector(selector);
    return !!(inputField && inputField.value);
  }
}
