import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.contentValue = this.contentTarget.value;
    $(this.contentTarget).on('keypress', (e) => e.preventDefault());
  }

  copy() {
    this.contentTarget.select();
    this.contentTarget.setSelectionRange(0, 99999);
    document.execCommand("copy");

    toastr.info(window.I18n.copy_content_controller.complete);
  }
}
