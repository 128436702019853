import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "navList" ];

  toggle(e) {
    e.preventDefault();
    e.target.parentNode.classList.toggle("bg-gray-200");
    this.navListTarget.classList.toggle("h-auto");
    this.navListTarget.classList.toggle("h-0");
  }
}
