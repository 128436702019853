import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "contactType", "editPhoneNumber", "editEmailAddress", "editWebsite", "editUsername" ];

  connect() {
    this.updateDisplay({ target: this.contactTypeTarget });
  }

  updateDisplay({ target: { value } }) {
    this.editPhoneNumberTarget.classList.add('d-none');
    this.editEmailAddressTarget.classList.add('d-none');
    this.editWebsiteTarget.classList.add('d-none');
    this.editUsernameTarget.classList.add('d-none');

    switch(value) {
      case "Phone Number":
        this.editPhoneNumberTarget.classList.remove('d-none');
        break;
      case "Email Address":
        this.editEmailAddressTarget.classList.remove('d-none');
        break;
      case "Website":
        this.editWebsiteTarget.classList.remove('d-none');
        break;
      case "Username":
        this.editUsernameTarget.classList.remove('d-none');
        break;
    }
  }
}
