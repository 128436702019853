import { Controller } from "@hotwired/stimulus"

const leavingConfirmationMessage = window.I18n.unsaved_changes_controller.confirm;

export default class extends Controller {
  connect() {
    this.changed = false;
    $(this.element).on('change', 'input, select, textarea', () => this.trackChange());

    $(window).on('submit', (e) => this.formSubmit(e));
    $(window).on('beforeunload', (e) => this.leavingPage(e));
    $(window).on('turbo:before-visit', (e) => this.leavingPage(e));
  }

  trackChange() {
    this.changed = true;
  }

  hasChanges() {
    return this.changed;
  }

  formSubmit(event) {
    this.changed = false;
  }

  leavingPage(event) {
    if (this.hasChanges()) {
      switch (event.type) {
        case "turbo:before-visit":
          if (!window.confirm(leavingConfirmationMessage)) {
            event.preventDefault()
          }
          break;
        case "beforeunload":
          event.returnValue = leavingConfirmationMessage;
          return event.returnValue;
      }
    }
  }
}
