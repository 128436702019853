import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (location.search && location.search != "") {
      this.filters = location.search.split('?', 2)[1].split('&').filter((f) => f.startsWith("filter")).map((f) => decodeURI(f));
    } else {
      this.filters = [];
    }
  }

  updateQuery({ target: { dataset: { actionFilter } } }) {
    actionFilter.split(",").forEach((filter) => {
      this.updateTextFilter(this.element.querySelector(`input[name="filters[${filter}]"]`));
    });
    this.updateFilters({ target: { type: "updateOnly" } });
  }

  updateFilters({ target: { type, checked, name, value } }) {
    switch (type) {
    case "checkbox":
      this.updateCheckboxFilter({ checked, name, value });
      break;
    case "text":
      this.updateTextFilter({ name, value });
      break;
    }

    if (this.filters.length > 0) {
      Turbo.visit(`${location.pathname}?${this.filters.join('&')}`, { action: "advance" })
    } else {
      Turbo.visit(location.pathname, { action: "advance" })
    }
  }

  updateTextFilter({ name, value }) {
    this.filters = this.filters.reduce((filters, filter) => {
      if (!filter.startsWith(`${name}=`)) {
        filters.push(filter);
      }
      return filters;
    }, []);

    if (value !== "") {
      this.filters.push(`${name}=${value}`)
    }
  }

  updateCheckboxFilter({ checked, name, value }) {
    const filter       = `${name}=${value}`;
    const filterIndex  = this.filters.indexOf(filter);
    const filterExists = filterIndex >= 0;

    if (filterExists) {
      this.filters.splice(filterIndex, 1);
    }

    if (checked) {
      this.filters.push(filter);
    }
  }

  clearFilters() {
    this.filters = [];
    Turbo.visit(location.pathname, { action: "advance" });
  }
}
