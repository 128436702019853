import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "table", "template" ];

  add() {
    const clonedRow = this.templateTarget.content.cloneNode(true);
    this.tableTarget.appendChild(clonedRow);
  }

  remove(e) {
    e.target.parentNode.parentNode.remove();
  }
}
