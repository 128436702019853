import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list", "item" ];

  initialize() {
    this.itemTargets.forEach((item, index) => { item.style.order = this.prevOrder(index); });
    this.play()
    this.element.addEventListener('mouseover', () => this.pause());
    this.element.addEventListener('mouseout', () => this.play());
  }

  next() {
    this.listTarget.classList.remove('is-reversing');
    this.itemTargets.forEach((item) => {
      item.style.order = this.nextOrder(parseInt(item.style.order));
    });
    this.listTarget.classList.remove('is-set');
    return setTimeout(() => {
      return this.listTarget.classList.add('is-set');
    }, 50);
  }

  prev() {
    this.listTarget.classList.add('is-reversing');
    this.itemTargets.forEach((item) => {
      item.style.order = this.prevOrder(parseInt(item.style.order));
    });
    this.listTarget.classList.remove('is-set');
    return setTimeout(() => {
      return this.listTarget.classList.add('is-set');
    }, 50);
  }

  prevOrder(currentOrder) {
    if (currentOrder + 1 <= this.itemTargets.length) {
      return currentOrder + 1;
    } else {
      return 1;
    }
  }

  nextOrder(currentOrder) {
    if (currentOrder - 1 >= 1) {
      return currentOrder - 1;
    } else {
      return this.itemTargets.length;
    }
  }

  play() {
    this.playTimer = setInterval(() => {
      this.next();
    }, 10000);
  }

  pause() {
    clearInterval(this.playTimer);
  }
}
