import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "replyForm" ];

  connect() {
    this.toggleButton = this.element.dataset.toggleButton || false;
  }

  toggleReplyForm(e) {
    e.preventDefault();
    this.replyFormTarget.classList.toggle('d-none');
    if (this.toggleButton) {
      e.target.classList.add('d-none');
    }
  }
}
