// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

global.toastr = require("toastr")
toastr.options.progressBar = true

// This is required for delete links to work
Rails.start()
global.Rails = Rails

Turbo.start()
ActiveStorage.start()

import "controllers"

require("trix")
require("@rails/actiontext")

require("jquery-ui");
require("jquery-ui-sortable");

import "./selectize";

$(document).on('turbo:load', function() {
  $(document).on('click', '[data-href]', (event) => {
    event.preventDefault();

    let firstHrefEl;
    if (event.currentTarget.dataset.href || event.currentTarget.href) {
      firstHrefEl = event.currentTarget;
    } else {
      firstHrefEl = event.originalEvent.path.find((el) => el.href || el.dataset.href);
    }
    const href        = firstHrefEl.href || firstHrefEl.dataset.href;

    Turbo.visit(href, { action: "advance" });
  });

  for (const el of $('[data-selectizable="true"]')) {
    const plugins    = el.dataset.selectizePlugins ? el.dataset.selectizePlugins.split(',') : [];
    const delimiter  = el.dataset.selectizeDelimiter || ',';
    const create     = el.dataset.selectizeCreate == 'false' ? false : (value) => ({ text: value, value });
    const dataSource = el.dataset.selectizeDataSource;

    const options = {
      persist: false,
      plugins,
      delimiter,
      create
    };

    if (dataSource) {
      options.load = async (query, callback) => {
        const response    = await fetch(`${dataSource}?query=${query}`);
        const reponseJson = await response.json();
        callback(reponseJson.map(({ name }) => ({ text: name, value: name })));
      }
    }

    $(el).selectize(options);
  };

  document.querySelectorAll('textarea[data-auto-grow="true"]').forEach((element) => {
    element.addEventListener('input', ({ target }) => {
      target.style.height = "";
      target.style.height = target.scrollHeight + "px";
    });
  });
});

import "./trix-extension";
