import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.shareUrl = this.element.dataset.shareUrl;
    this.title    = this.element.dataset.shareTitle;
    this.site     = this.element.dataset.shareSite;

    $(this.element).on('click', '[data-share-site]', (e) => this.openUrl(e));
  }

  openUrl(e) {
    e.preventDefault();
    const width         = 640;
    const height        = 480;
    const left          = (screen.width / 2) - (width / 2);
    const top           = (screen.height * 0.3) - (height / 2);
    const windowOptions = `width=${width},height=${height},left=${left},top=${top},menubar=no,status=no,location=no`;
    window.open(this.url(e.target.dataset.shareSite), 'popup', windowOptions)
  }

  url(shareSite) {
    switch(shareSite) {
      case 'twitter':
        return `https://twitter.com/intent/tweet?url=${this.shareUrl}&text=${this.title}`;
      case 'facebook':
        return `http://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`;
      case 'linkedin':
        return `https://www.linkedin.com/shareArticle?mini=true&url=${this.shareUrl}&title=${this.title}&summary="Check this out!"`;
    }
  }
}
