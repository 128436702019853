import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "preview" ];

  update({ target: { files } }) {
    if (files && files[0]) {
      const reader = new FileReader();
      if (this.previewTarget.tagName == "IMG") {
        reader.onload = ({ target: { result } }) => this.previewTarget.setAttribute("src", result);
      } else {
        reader.onload = ({ target: { result } }) => this.previewTarget.setAttribute("style", `background-image:url(${result})`);
      }
      reader.readAsDataURL(files[0]);
    }
  }
}
