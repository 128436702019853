import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "label", "hiddenElement" ];

  connect() {
    this.toggleHiddenElementState(this.checkboxTarget.checked);
    this.checkboxTarget.addEventListener('change', (_) => this.toggleHiddenElementState(this.checkboxTarget.checked));
    this.labelTarget.addEventListener('click', (_) => this.checkboxTarget.click());
  }

  toggleHiddenElementState(checked) {
    if (this.hiddenElementTarget) {
      const { dataset: { hideWhen } } = this.hiddenElementTarget;
      switch(hideWhen) {
        case "checked":
          if (checked) {
            this.hiddenElementTarget.classList.add('hidden')
          } else {
            this.hiddenElementTarget.classList.remove('hidden')
          }
          break;
        default:
          if (checked) {
            this.hiddenElementTarget.classList.remove('hidden')
          } else {
            this.hiddenElementTarget.classList.add('hidden')
          }
          break;
      }
    }
  }
}
