import Trix from "trix"
import Rails from "@rails/ujs"

const YOUTUBE_EMBED_REGEX = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/;

class BaseElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }
}

function innerHTML(alignment) {
  return `
    <style>:host { text-align: ${alignment}; width: 100%; display: block; }</style>
    <slot></slot>
  `;
}

class AlignLeftElement extends BaseElement {
  constructor() {
    super();
    this.shadowRoot.innerHTML = innerHTML('left');
  }
}

class AlignCenterElement extends BaseElement {
  constructor() {
    super();
    this.shadowRoot.innerHTML = innerHTML('center');
  }
}

class AlignRightElement extends BaseElement {
  constructor() {
    super();
    this.shadowRoot.innerHTML = innerHTML('right');
  }
}

class AlignJustifyElement extends BaseElement {
  constructor() {
    super();
    this.shadowRoot.innerHTML = innerHTML('justify');
  }
}

window.customElements.define('align-left', AlignLeftElement);
window.customElements.define('align-center', AlignCenterElement);
window.customElements.define('align-right', AlignRightElement);
window.customElements.define('align-justify', AlignJustifyElement);

const toolbarDefultHTML = () => {
  const { lang } = Trix.config;
  return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${lang.code}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
      </span>
      <span class="trix-button-group trix-button-group--alignment-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="Align Left" tabindex="-1">Align Center</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-align-justify" data-trix-attribute="alignJustify" title="Align Justify" tabindex="-1">Align Justify</button>
      </span>
      <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input />
          <div class="flex">
            <input type="button" class="px-2 py-1 bg-blue-400 text-white rounded mx-2 cursor-pointer" value="${lang.link}" data-trix-method="setAttribute" />
            <input type="button" class="px-2 py-1 border border-slate-400 rounded mx-2 cursor-pointer" value="${lang.unlink}" data-trix-method="removeAttribute" />
          </div>
        </div>
        <div data-behavior="embed_container">
          <div class="link_to_embed link_to_embed--new mt-2">
            Would you like to embed media from this site?
            <input class="px-2 py-1 ml-3 border-orange-400 border rounded cursor-pointer" type="button" data-behavior="embed_url" value="Yes, embed it" />
          </div>
        </div>
      </div>
    </div>
  `;
}

const fetchYoutubeData = ({ youtubeId, error, success }) => {
  Rails.ajax({
    url: `/youtube/${encodeURIComponent(youtubeId)}`,
    type: 'get',
    error,
    success
  })
}

const processLinkInput = ({ target }) => {
  const toolbarElement = target.closest('trix-toolbar');
  const embedContainerElement = toolbarElement.querySelector("[data-behavior='embed_container']");

  const { value } = target;
  const matches = value.trim().match(YOUTUBE_EMBED_REGEX)
  console.log(value, matches)

  if (matches != null) {
    embedContainerElement.style.display = "block"
  } else {
    embedContainerElement.style.display = "none";
  }
}

const embedYoutubeVideo = ({ target }) => {
  const editorElement = target.closest('.form-group').querySelector('trix-editor');
  const { editor, toolbarElement } = editorElement;
  const embedContainerElement = toolbarElement.querySelector("[data-behavior='embed_container']");

  const { value } = target.closest('.trix-dialog').querySelector('input[type=url]');
  const matches = value.trim().match(YOUTUBE_EMBED_REGEX)

  if (matches != null) {
    fetchYoutubeData({
      youtubeId: matches[2],
      error: () => (embedContainerElement.style.display = "none"),
      success: (data) => {
        const attachment = new Trix.Attachment(data);
        editor.insertAttachment(attachment);
        editorElement.focus();
      }
    });
  }
}

const enableYoutubeEmbedding = (editorElement) => {
  const { toolbarElement } = editorElement;
  const hrefElement = toolbarElement.querySelector("[data-trix-input][name='href']");
  const embedElement = toolbarElement.querySelector("[data-behavior='embed_url']");

  hrefElement.addEventListener("input", processLinkInput);
  hrefElement.addEventListener("focusin", processLinkInput);
  hrefElement.removeAttribute("required")
  embedElement.addEventListener("click", embedYoutubeVideo);
}

const updateTrixToolbars = () => {
  const toolbars = document.querySelectorAll('trix-editor');
  const html = Trix.config.toolbar.getDefaultHTML();
  toolbars.forEach((editor) => {
    editor.toolbarElement.innerHTML = html;
    enableYoutubeEmbedding(editor);
  });
}

Trix.config.toolbar.getDefaultHTML = toolbarDefultHTML;

Trix.config.blockAttributes.alignLeft = {
  tagName: 'align-left',
  parse: false,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignCenter = {
  tagName: 'align-center',
  parse: false,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignRight = {
  tagName: 'align-right',
  parse: false,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignJustify = {
  tagName: 'align-justify',
  parse: false,
  nestable: false,
  exclusive: true,
};

document.addEventListener("trix-initialize", updateTrixToolbars, { once: true });
