import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "flaggableType", "flaggableId"];

  openModal(event) {
    event.preventDefault();

    const { target: { dataset: { flaggableType, flaggableId } } } = event;

    this.flaggableTypeTarget.value = flaggableType;
    this.flaggableIdTarget.value   = flaggableId;

    $(this.modalTarget).modal();
  }
}
