import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "title",
    'role',
    "step1",
    "step2"
  ];

  connect() {
    this.titleTarget.textContent = window.I18n.registration_controller.i_want_to;
  }

  selectHelperRole() {
    this.titleTarget.textContent = window.I18n.registration_controller.register_first;
    this.roleTarget.value = 'helper';
    this.step1Target.classList.add('d-none');
    this.step2Target.classList.remove('d-none');
  }

  selectHelpSeekerRole() {
    this.roleTarget.value = 'help_seeker';
    this.step1Target.classList.add('d-none');
    this.step2Target.classList.remove('d-none');
    this.titleTarget.textContent = window.I18n.registration_controller.register_first;
  }
}
